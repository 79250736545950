import React from 'react';
import { HeroWrapper, HeroGallery } from './files';

const PropertyHero = ({ contentSection }) => (
  <HeroWrapper>
    <HeroGallery contentSection={contentSection} />
  </HeroWrapper>
);

export default PropertyHero;
