import React, { Component, Fragment } from 'react';
import { graphql } from 'gatsby';

// Components
import SeoDetails from '../components/common/seoContent';
import PropertyHero from '../components/pages/properties/hero';

// Context
import ContextConsumer from '../components/common/contextProvider';

const PropertyTemplate = ({ data }) => (
  <ContextConsumer>
    {({ context }) => <Content data={data} context={context} />}
  </ContextConsumer>
);

class Content extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { data } = this.props;
    const { seoContent, contentSections } = data.contentfulProperties;

    return (
      <Fragment>
        <SeoDetails seoContent={seoContent} />
        <PropertyHero contentSection={contentSections[0]} />
      </Fragment>
    );
  }
}

export const query = graphql`
  query($id: String!) {
    contentfulProperties(id: { eq: $id }) {
      id
      title
      seoContent {
        pageTitle
        menuTitle
        slug
        description {
          description
        }
        featuredImage {
          title
          description
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
      contentSections {
        title
        imageGallery {
          fluid(maxWidth: 2000) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          file {
            url
            contentType
          }
        }
      }
    }
  }
`;

export default PropertyTemplate;
