import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Slider from 'react-slick';

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  .gatsby-image-wrapper {
    height: 75vh;
    max-height: 42em;
    min-height: 32em;
    padding: 0;
    position: relative;
    margin-bottom: 5em;
    overflow: hidden;
    text-align: center;
    @media (max-width: ${props => props.theme.responsive.small}) {
      margin-bottom: 3em;
      max-height: 32em;
      min-height: 24em;
      height: 50vh;
    }
  }
`;

const settings = {
  dots: false,
  autoplay: true,
  infinite: true,
  fade: true,
  slidesToShow: 1,
  slidesToScroll: 1
};

const HeroGallery = ({ contentSection }) => (
  <Wrapper>
    <Slider {...settings}>
      {contentSection.imageGallery.map(g => (
        <Img key={g.id} fluid={g.fluid} alt={g.description} />
      ))}
    </Slider>
  </Wrapper>
);

export default HeroGallery;
